<template>
  <div class="layout container">

    <!-- 패널 -->
    <component :is="Panel" :mode="`container`" :key="user.login" />

    <!-- 헤더 -->
    <component :is="Header" :mode="`container`" />

    <!-- 컨텐츠 -->
    <div :class="`contents expansion ${resolution.width > resolution.notebook ? `` : `mobile`}`">
      <div class="contents-inside">
        <div class="contents-inside-wide">
          <div class="contents-inside-wide-cover">
            <div v-if="resolution.width > resolution.notebook" class="contents-inside-wide-left">
              <div class="contents-inside-wide-contents">
                <ClientOnly>
                  <component
                    :is="MelodyStatisticsLnb"
                    :id="id"
                    :menus="statisticsMenus"
                  />
                </ClientOnly>
              </div>
            </div>
            <div class="contents-inside-wide-right">
              <div class="contents-inside-wide-contents">
                <component :is="ErrorUI" v-if="error" :state="error" :key="`${error}-error`" />
                  <ClientOnly>
                    <component
                      v-if="resolution.width <= resolution.notebook"
                      :is="MelodyStatisticsBar"
                      :id="id"
                      :menus="statisticsMenus"
                    />
                  </ClientOnly>
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 푸터 -->
    <component :is="Footer" :mode="`container`" />

  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useGlobalStore } from '~/store/global'
// import Error from '~/component/state/error.vue'
// import Panel from '~/component/element/panel.vue'
// import Header from '~/component/layout/header.vue'
// import Footer from '~/component/layout/footer.vue'
// import MelodyStatisticsLnb from '~/component/game/melody/statistics/lnb.vue'
// import MelodyStatisticsBar from '~/component/game/melody/statistics/bar.vue'

export default {
  async setup () {
    
    // 글로벌 스토어
    const { user } = storeToRefs(useAuthStore())
    const { error, layout, resolution } = storeToRefs(useGlobalStore())
    let $i18n: any = ref(globalVariables.app.i18n)

    // 파라미터 정의
    const id: any = ref(Number(layout.value?.params?.id || 0) || 0)

    // 메뉴 설정
    let statisticsMenus: any = ref({})
    function updateStatisticsMenus () {
      statisticsMenus.value = globalFunctions.deepCopy(globalVariables.gameMelodyStatisticsLnb)
      statisticsMenus.value.items = globalFunctions.getTranslateText(statisticsMenus.value.items, $i18n.value)
      statisticsMenus.value.items = statisticsMenus.value.items.map((item: any) => ({
        ...item,
        url: item.url.replace('{#id}', id.value),
        selected: item.key === layout.value?.name
      }))
    }
    updateStatisticsMenus()

    // 메모리 비우기
    function restore () {
      ErrorUI.value = null
      Panel.value = null
      Header.value = null
      Footer.value = null
      MelodyStatisticsLnb.value = null
      MelodyStatisticsBar.value = null
      $i18n.value = null
      statisticsMenus.value = null
      id.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const ErrorUI: any = shallowRef(null)
    const Panel: any = shallowRef(null)
    const Header: any = shallowRef(null)
    const Footer: any = shallowRef(null)
    const MelodyStatisticsLnb: any = shallowRef(null)
    const MelodyStatisticsBar: any = shallowRef(null)
    const loadComponent = async () => {
      const ErrorUI_load = await import('~/component/state/error.vue')
      const Panel_load = await import('~/component/element/panel.vue')
      const Header_load = await import('~/component/layout/header.vue')
      const Footer_load = await import('~/component/layout/footer.vue')
      const MelodyStatisticsLnb_load = await import('~/component/game/melody/statistics/lnb.vue')
      const MelodyStatisticsBar_load = await import('~/component/game/melody/statistics/bar.vue')
      ErrorUI.value = ErrorUI_load.default || ErrorUI_load
      Panel.value = Panel_load.default || Panel_load
      Header.value = Header_load.default || Header_load
      Footer.value = Footer_load.default || Footer_load
      MelodyStatisticsLnb.value = MelodyStatisticsLnb_load.default || MelodyStatisticsLnb_load
      MelodyStatisticsBar.value = MelodyStatisticsBar_load.default || MelodyStatisticsBar_load
    }
    await loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      user,
      error,
      resolution,
      id,
      statisticsMenus,
      updateStatisticsMenus,

      // 컴포넌트
      ErrorUI,
      Panel,
      Header,
      Footer,
      MelodyStatisticsLnb,
      MelodyStatisticsBar
    }
  },
  watch: {
    '$i18n.locale' () {
      this.updateStatisticsMenus()
    },
    '$route' (to) {
      this.updateStatisticsMenus()
    }
  }
}
</script>